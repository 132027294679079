import { createSlice, PayloadAction } from 'redux-starter-kit';
import { IPostsState } from './types';
import { ErrorType } from '../types';
import { IRedditLinkType } from '../../services/reddit/types';
import { AllGenresType, INormalPosts } from 'utils/normalizePosts';

const initialState: IPostsState = {
  loading: false,
  byId: {},
  allIds: [],
  allGenres: [],
  byGenre: {},
  activePostId: '',
  error: '',
  filterStr: ''
};

const posts = createSlice({
  slice: 'posts',
  initialState,
  reducers: {
    load: (state: IPostsState): IPostsState => {
      state.loading = true;
      return state;
    },
    search: (state: IPostsState): IPostsState => {
      state.loading = true;
      return state;
    },
    loadAfter: (state: IPostsState): IPostsState => {
      state.loading = true;
      return state;
    },
    loadSuccess: (
      state: IPostsState,
      {
        payload: { allIds, byId, allGenres, byGenre }
      }: PayloadAction<INormalPosts>
    ): IPostsState => {
      state.loading = false;
      state.byId = { ...state.byId, ...byId };
      state.allIds = Array.from(new Set([...state.allIds, ...allIds]));
      state.byGenre = { ...state.byGenre, ...byGenre };
      state.allGenres = [...state.allGenres, ...allGenres];
      return state;
    },
    loadFailure: (
      state: IPostsState,
      { payload }: PayloadAction<ErrorType>
    ): IPostsState => {
      state.error = payload.error;
      return state;
    },
    clear: (): IPostsState => {
      return initialState;
    },
    setActivePostId: (
      state: IPostsState,
      { payload }: PayloadAction<string>
    ): IPostsState => {
      state.activePostId = payload;
      return state;
    },
    next: (state: IPostsState): IPostsState => {
      const { activePostId } = state;
      const validIds = state.allIds.filter(postId =>
        state.byId[postId].title
          .toLowerCase()
          .includes(state.filterStr.toLowerCase())
      );
      const currentIndex = validIds.findIndex(id => id === activePostId);
      const nextIndex =
        currentIndex === -1
          ? 0
          : currentIndex === validIds.length - 1
          ? 0
          : currentIndex + 1;
      state.activePostId = validIds[nextIndex];
      return state;
    },
    previous: (state: IPostsState): IPostsState => {
      const { activePostId } = state;
      const validIds = state.allIds.filter(postId =>
        state.byId[postId].title
          .toLowerCase()
          .includes(state.filterStr.toLowerCase())
      );
      const currentIndex = validIds.findIndex(id => id === activePostId);
      const nextIndex =
        currentIndex === -1 || currentIndex === 0
          ? validIds.length - 1
          : currentIndex - 1;
      state.activePostId = validIds[nextIndex];
      return state;
    },
    random: (state: IPostsState): IPostsState => {
      const valid = state.allIds.filter(postId =>
        state.byId[postId].title
          .toLowerCase()
          .includes(state.filterStr.toLowerCase())
      );
      state.activePostId =
        valid[Math.floor(Math.random() * Math.floor(valid.length))];
      return state;
    },
    filter: (
      state: IPostsState,
      { payload }: PayloadAction<string>
    ): IPostsState => {
      state.filterStr = payload;
      return state;
    }
  }
});

export const getIsPostsLoading = (state: IPostsState): boolean => state.loading;

export const getActivePostId = (state: IPostsState): string =>
  state.activePostId;

export const getPostById = (state: IPostsState, id: string): IRedditLinkType =>
  state.byId[id] || {};

export const getPosts = (state: IPostsState): Array<IRedditLinkType> =>
  state.allIds
    .map(id => state.byId[id])
    .filter(post =>
      post.title.toLowerCase().includes(state.filterStr.toLowerCase())
    );

export const getLastLoadedPostId = (state: IPostsState): string => {
  const validPosts = state.allIds.filter(postId =>
    state.byId[postId].title
      .toLowerCase()
      .includes(state.filterStr.toLowerCase())
  );
  return validPosts[validPosts.length - 1];
};

export const getAllGenres = (state: IPostsState): AllGenresType =>
  state.allGenres;

export const getActiveFilter = (state: IPostsState): string => state.filterStr;

export default posts;
