import { combineReducers } from 'redux';

import { IApplicationState } from './types';
import { IRedditLinkType } from 'services/reddit/types';

import posts, * as fromPosts from './posts';
import settings, * as fromSettings from './settings';
import auth, * as fromAuth from './auth';
import { AllGenresType } from '../utils/normalizePosts';

export { postActions } from './posts';
export { authActions } from './auth';
export { settingsActions } from './settings';

export default combineReducers({
  posts,
  settings,
  auth
});

export const getActivePostId = (state: IApplicationState): string =>
  fromPosts.getActivePostId(state.posts);

export const getIsLoading = (state: IApplicationState): boolean =>
  fromPosts.getIsPostsLoading(state.posts) ||
  fromAuth.getIsAuthLoading(state.auth);

export const getAllPosts = (state: IApplicationState): Array<IRedditLinkType> =>
  fromPosts.getPosts(state.posts);

export const getAllGenres = (state: IApplicationState): AllGenresType =>
  fromPosts.getAllGenres(state.posts);

export const getLastLoadedPostId = (state: IApplicationState): string =>
  fromPosts.getLastLoadedPostId(state.posts);

export const getPostById = (
  state: IApplicationState,
  id: string
): IRedditLinkType => fromPosts.getPostById(state.posts, id);

export const getNSFWFlagSetting = (state: IApplicationState): boolean =>
  fromSettings.getNSFWFlag(state.settings);

export const getActivePostFilter = (state: IApplicationState): string =>
  fromPosts.getActiveFilter(state.posts);
