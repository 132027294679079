/* *
 *  Reddit Types:
 *  t1: Comment
 *  t2: Account
 *  t3: Link
 *  t4: Message
 *  t5: Subreddit
 *  t6: Award
 *
 *  after: string;
 *  before: string;
 *  limit: number;  the maximum number of items to return in this slice of the listing
 *  count: number;  the number of items already seen in this listing
 *  show: boolean;  optional parameter - if all is passed, filters such as "hide links that I have voted on" will be disabled.
 */

import { SongMetaData } from '../../types/types';

export type Comment = 't1';
export type Account = 't2';
export type Link = 't3';
export type Message = 't4';
export type Subreddit = 't5';
export type Award = 't6';

export type MediaProviderType = 'YouTube' | 'SoundCloud' | 'Spotify';

export enum SortBy {
  HOT = 'hot',
  NEW = 'new',
  RISING = 'rising',
  CONTROVERSIAL = 'controversial',
  TOP = 'top',
  GILDED = 'gilded'
}

export enum TimeFrame {
  HOUR = 'hour',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
  ALL_TIME = 'all'
}

export interface IListing<T> {
  kind: 'Listing';
  data: IRedditListingWithAfter<T> | IRedditListingWithBefore<T>;
}

export interface IRedditListingWithBefore<T> {
  before: string;
  limit?: number;
  count?: number;
  show?: boolean;
  children: Array<T>;
}

export interface IRedditListingWithAfter<T> {
  before: string;
  limit?: number;
  count?: number;
  show?: boolean;
  children: Array<T>;
}

export interface IRedditCommentDataList {
  kind: Comment;
}

export interface IRedditLinkDataList {
  kind: Link;
  data: IRedditLinkType;
}

export interface IRedditMessageDataList {
  kind: Message;
  // data:
}

export interface IRedditSubredditDataList {
  kind: Subreddit;
}

export interface IRedditAwardDataList {
  kind: Award;
}

export interface IRedditLinkType {
  name: string; // ID
  author: string; // user
  selftext: string; // post description
  stickied: boolean;
  title: string; // post title
  thumbnail: string;
  thumbnail_height: number;
  thumbnail_width: number;
  url: string;
  visited: boolean;
  created_utc: number;
  domain: string;
  over_18: boolean; // NSFW
  media: IMedia;
  is_self: boolean;
  _metadata?: SongMetaData;
}

export interface IMedia {
  oembed: IOEmbed;
}
export interface IOEmbed {
  author_name: string;
  author_url: string;
  height: number;
  width: number;
  provider_name: MediaProviderType;
}
