import React from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from 'reducer/types';
import {
  getActivePostId,
  getAllPosts,
  getPostById,
  postActions
} from 'reducer';
import { IRedditLinkType } from 'services/reddit/types';
import Post, { Mode } from '../post';
import './posts.scss';
import classNames from 'classnames';

export interface OwnProps {
  mode: Mode;
}

interface StateProps {
  posts: Array<IRedditLinkType>;
  activePost: IRedditLinkType;
}

interface DispatchProps {
  setActivePostId: (id: string) => {};
}

type IPostsProps = StateProps & DispatchProps & OwnProps;

const Posts: React.FC<IPostsProps> = ({
  posts,
  activePost,
  setActivePostId,
  mode
}) => {
  const activeId = activePost ? activePost.name : undefined;
  const gallery = mode === Mode.Gallery;

  return (
    <div className={classNames('posts', { gallery })}>
      {posts.map(post => (
        <Post
          key={post.name}
          mode={mode}
          post={post}
          activeId={activeId}
          onClick={setActivePostId}
        />
      ))}
    </div>
  );
};

const mapStateToProps = (
  state: IApplicationState,
  ownProps: OwnProps
): StateProps => ({
  posts: getAllPosts(state),
  activePost: getPostById(state, getActivePostId(state))
});

const mapDispatchToProps: DispatchProps = {
  setActivePostId: postActions.setActivePostId
};

export default connect<StateProps, DispatchProps, OwnProps, IApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(Posts);
