import { createSlice } from 'redux-starter-kit';
import { IAuthState } from './types';

const initialState: IAuthState = {
  loading: false,
  error: ''
};

const auth = createSlice({
  slice: 'auth',
  initialState,
  reducers: {
    login: (state: IAuthState): IAuthState => {
      state.loading = true;
      return state;
    },
    loginSuccess: (state: IAuthState): IAuthState => {
      state.loading = false;
      return state;
    }
  }
});

export const getIsAuthLoading = (state: IAuthState): boolean => state.loading;

export const getAuthError = (state: IAuthState): string => state.error;

export default auth;
