import React, { ChangeEvent, useRef } from 'react';
import { useRect } from '@reach/rect';
import { Input, Select, Icon, Row, Col } from 'antd';
import { SortBy } from 'services/reddit/types';
import './filters.scss';

const { Option } = Select;

const SortOptions = [
  { value: SortBy.TOP, title: 'Top' },
  { value: SortBy.HOT, title: 'Hot' },
  { value: SortBy.NEW, title: 'New' },
  { value: SortBy.RISING, title: 'Rising' },
  { value: SortBy.CONTROVERSIAL, title: 'Controversial' },
  { value: SortBy.GILDED, title: 'Gilded' }
];

interface IFilterProps {
  onSortByChange: (value: string) => void;
  onFilter: (filterStr: string) => void;
  defaultValue: string;
  disabled: boolean;
}
let top = 0;
const Filters: React.FC<IFilterProps> = ({ onSortByChange, onFilter }) => {
  const filterEl = useRef(null);
  const rect = useRect(filterEl, top === 0);
  top = rect ? rect.top : top;
  const onChange = (evt: ChangeEvent<HTMLInputElement>) =>
    onFilter(evt.target.value);
  return (
    <div ref={filterEl} className="filters">
      <Row type="flex" justify="space-between" align="bottom">
        <Col span={9} md={8}>
          <Select
            defaultValue={SortBy.TOP}
            style={{ width: 120 }}
            onChange={onSortByChange}
            size="large"
          >
            {SortOptions.map(opt => (
              <Option key={opt.value}>{opt.title}</Option>
            ))}
          </Select>
        </Col>
        <Col span={0} md={6}>
          <h1>r/listentothis</h1>
        </Col>

        <Col span={13} md={8}>
          <Input
            id="filter"
            allowClear
            size="large"
            placeholder="Text to filter posts"
            onChange={onChange}
            prefix={<Icon type="filter" style={{ color: 'rgba(0,0,0,.25)' }} />}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Filters;
