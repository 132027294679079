import { spawn } from 'redux-saga/effects';

import postSaga from './posts';
import profileSaga from './profile';
import authSaga from './auth';
export default function* rootSaga() {
  yield spawn(postSaga);
  yield spawn(profileSaga);
  yield spawn(authSaga);
}
