import React from 'react';
import { Button, Icon, Layout, Menu } from 'antd';
import { IAppProps, IAppState } from './types';
import { Filters, Player, Posts } from 'components';
import { SortBy } from 'services/reddit/types';

import './app.scss';
import { Mode } from '../components/post';

const { Content, Sider } = Layout;

class App extends React.Component<IAppProps, IAppState> {
  static defaultProps = {
    loading: false
  };

  state = {
    sortingBy: SortBy.HOT,
    initialSearch: true,
    listView: false,
    dev: false
  };

  constructor(props: IAppProps) {
    super(props);
    console.log(window.location.search);
    if (window.location.search.includes('dev=true')) this.state.dev = true;
  }

  componentDidMount(): void {
    this.props.loadPosts({ after: undefined, sortBy: this.state.sortingBy });
  }

  handleSiderCollapse = (collapsed: boolean) => {
    this.setState(() => ({ listView: collapsed }));
  };

  handleClickMore = (): void => {
    this.props.loadMore({
      after: this.props.lastPostId,
      sortBy: this.state.sortingBy
    });
  };

  handleSortByChange = (value: string): void => {
    this.props.clear();
    this.setState({ sortingBy: value });
    this.props.loadPosts({ after: undefined, sortBy: value });
  };

  handleSearch = (): void => {
    const { initialSearch } = this.state;
    const after = initialSearch ? undefined : this.props.lastPostId;
    this.props.search({ after, sortBy: this.state.sortingBy });
    this.setState({ initialSearch: false });
  };

  handleFilter = (value: string): void => {
    this.props.filter(value);
  };

  render() {
    const {
      activePost,
      next,
      previous,
      random,
      loading,
      activeFilter,
      login
      // allGenres
    } = this.props;
    const { listView, dev } = this.state;

    return (
      <Layout className="app">
        <Sider
          width={280}
          className="sider"
          breakpoint="md"
          collapsedWidth="0"
          zeroWidthTriggerStyle={{ zIndex: 11 }}
          onCollapse={this.handleSiderCollapse}
        >
          <h1 className="logo">
            <Icon type="sound" />
            ReddTunes
          </h1>
          {activePost && (
            <Player
              autoplay={1}
              onNext={next}
              onPrevious={previous}
              onRandom={() => random()}
              activePost={activePost}
            />
          )}
          <h3>
            <Icon type="desktop" />
            Available Communities
          </h3>
          <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
            <Menu.Item key="1">
              <Icon type="reddit" />
              <span>r/ListenToThis</span>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout className="body">
          {dev && (
            <div className="acct-actions">
              <Button onClick={login} type="primary">
                <Icon type="user" />
                <span>Login</span>
              </Button>
            </div>
          )}
          <Content className="main-content">
            <Filters
              onSortByChange={this.handleSortByChange}
              onFilter={this.handleFilter}
              defaultValue={SortBy.TOP}
              disabled={false}
            />
            <Posts mode={listView ? Mode.List : Mode.Gallery} />
            <Button
              block
              size="large"
              type="link"
              loading={loading}
              onClick={activeFilter ? this.handleSearch : this.handleClickMore}
            >
              {activeFilter
                ? `Search r/listentothis for more "${activeFilter}"`
                : 'Load more posts...'}
            </Button>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default App;
