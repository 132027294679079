import { filter } from 'ramda';
import { IRedditLinkDataList, IRedditLinkType } from '../services/reddit/types';
import parser from './parser';

export type ByIdType = { [id: string]: IRedditLinkType };
export type AllIdsType = Array<string>;
export type ByGenreType = { [genre: string]: Array<string> };
export type AllGenresType = Array<string>;

export interface INormalPosts {
  byId: ByIdType;
  allIds: AllIdsType;
  byGenre: ByGenreType;
  allGenres: AllGenresType;
}

export default function normalizePosts(
  posts: Array<IRedditLinkDataList>,
  filters: (value: IRedditLinkDataList) => boolean
): INormalPosts {
  const allIds: AllIdsType = [];
  const allGenres: AllGenresType = [];
  const byGenre: ByGenreType = {};

  const checkAndAddGenre = (genre: string, data: IRedditLinkType) => {
    const _genre = genre.toLowerCase().trim();
    byGenre[_genre]
      ? !byGenre[_genre].includes(data.name) && byGenre[_genre].push(data.name)
      : (byGenre[_genre] = [data.name]);
    !allGenres.includes(_genre) && allGenres.push(_genre);
  };

  const byId = filter(filters, posts).reduce<ByIdType>((acc, { data }) => {
    const _metadata = parser.parseListenToThisTitle(data.title);
    if (_metadata.error) return acc; // do not normalize posts with error reports
    if (!acc[data.name]) {
      acc[data.name] = { ...data, _metadata };
      const { genres } = _metadata;
      if (Array.isArray(genres)) {
        genres.forEach(genre => {
          checkAndAddGenre(genre, data);
        });
      } else {
        checkAndAddGenre(genres, data);
      }
      allIds.push(data.name);
    }
    return acc;
  }, {});
  return {
    byId,
    allIds,
    allGenres,
    byGenre
  };
}
