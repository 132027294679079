import { SongMetaData } from 'types/types';

const parseListenToThisTitle = (str: string): SongMetaData => {
  let data = decodeHtml(str).match(
    /(.*?)\s[—\-–]{1,2}\s(.*?)\s?\[(.*?)]\s?\(?(\d*)?\)?/
  );
  let artist: string = '',
    track: string = '',
    genres: string[] = [],
    year: number = 0,
    error: boolean = false;

  if (data) {
    error = !isNaN(Number(data[3])) || Number.isNaN(Number(data[4]));
    artist = data[1];
    track = data[2];
    genres = data[3].split('/');
    year = Number.isNaN(Number(data[4])) ? 0 : Number(data[4]);
  } else {
    error = true;
  }

  return {
    artist,
    track,
    genres,
    year,
    error
  };
};

function decodeHtml(html: string): string {
  var txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
}

export default { parseListenToThisTitle };
