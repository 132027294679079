import ky from 'ky';
import { getURLParams, getUrl } from './utils';
import { IListing, IRedditLinkDataList, SortBy, TimeFrame } from './types';

const SUPPORTED_PROVIDERS = ['YouTube'];

async function loadPosts(
  sort: SortBy,
  after?: string,
  limit?: string,
  time?: TimeFrame,
  searchTerm?: string | undefined
): Promise<Array<IRedditLinkDataList>> {
  const params = getURLParams({ q: searchTerm, after, limit, t: time });

  const response = await ky
    .get(getUrl(searchTerm, params, sort))
    .json<IListing<IRedditLinkDataList>>();
  const {
    data: { children }
  } = response;

  return children.filter(
    ({ data }) =>
      !data.is_self &&
      data.media &&
      data.media.oembed &&
      SUPPORTED_PROVIDERS.includes(data.media.oembed.provider_name)
  );
}

export default {
  load: loadPosts
};
