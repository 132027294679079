import { configureStore, getDefaultMiddleware } from "redux-starter-kit";
import createSagaMiddleware from "redux-saga";
import rootReducer from "../reducer";
import rootSaga from 'sagas';

const sagaMiddleware = createSagaMiddleware();

let middleware = [...getDefaultMiddleware(), sagaMiddleware];

const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: process.env.NODE_ENV !== "production"
});

sagaMiddleware.run(rootSaga);

export default store;
