import { SortBy, TimeFrame } from './types';

const BASE_URL: string = 'https://www.reddit.com/r/listentothis';

interface ILoadParams {
  q?: string;
  after?: string;
  limit?: string;
  t?: TimeFrame;
}

const getUrl = (
  searchTerm: string | undefined,
  params: string,
  sort?: SortBy
) => {
  const _sort = sort ? `/${sort}` : '';

  return searchTerm
    ? `${BASE_URL}/search.json${params}&restrict_sr=1`
    : `${BASE_URL}${_sort}.json${params}&sort=top`;
};

const getURLParams = (paramsObject: ILoadParams): string => {
  const paramsArray = [];
  for (let [key, val] of Object.entries(paramsObject)) {
    if (val) paramsArray.push([key, val]);
  }
  return `?${new URLSearchParams(paramsArray).toString()}`;
};

export { getURLParams, getUrl };
