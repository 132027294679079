import React from 'react';
import { Button } from 'antd';
import './player.scss';

interface IControlProps {
  onNext: () => void;
  onPrevious: () => void;
  onRandom: () => void;
}

const Controls: React.FC<IControlProps> = ({
  onNext,
  onPrevious,
  onRandom
}) => {
  return (
    <div className="controls">
      <Button size="large" onClick={onPrevious} icon="step-backward" />
      <Button size="large" onClick={onRandom} icon="swap" />
      <Button size="large" onClick={onNext} icon="step-forward" />
    </div>
  );
};

export default Controls;
