import { takeLatest, call, put, select } from '@redux-saga/core/effects';
import { compose } from 'ramda';
import { reddit } from 'services';
import { getNSFWFlagSetting, postActions, getActivePostFilter } from 'reducer';
import { PayloadAction } from 'redux-starter-kit';
import { IRedditLinkDataList, SortBy, TimeFrame } from 'services/reddit/types';
import { normalizePosts } from 'utils';

const DEFAULT_LIMIT = '100';
const DEFAULT_TIME_FRAME = TimeFrame.ALL_TIME;

function* fetchPosts({
  payload
}: PayloadAction<{ after: string; sortBy: SortBy }>) {
  try {
    const nsfwFlag = yield select(getNSFWFlagSetting);
    const filterStr = yield select(getActivePostFilter);

    const postList: Array<IRedditLinkDataList> = yield call(
      reddit.load,
      payload.sortBy,
      payload.after,
      DEFAULT_LIMIT,
      DEFAULT_TIME_FRAME,
      filterStr !== '' ? filterStr : null
    );

    const filters = compose(
      ({ data }: IRedditLinkDataList) => data.over_18 === nsfwFlag
    );
    yield put(postActions.loadSuccess(normalizePosts(postList, filters)));
  } catch (e) {
    yield put(postActions.loadFailure(e.toString()));
  }
}

export default function* postSaga() {
  yield takeLatest(
    [
      postActions.load.type,
      postActions.loadAfter.type,
      postActions.search.type
    ],
    fetchPosts
  );
}
