import { takeLatest, call } from 'redux-saga/effects';
import { authActions } from 'reducer';

async function getAuthState() {
  const response = await fetch('/.netlify/lambdas/generate_reddit_state');

  return await response.json();
}

function* startAuthentication() {
  const { auth_url, ...params } = yield call(getAuthState);
  let paramStr = Object.entries(params)
    .map(([key, val]) => `${key}=${val}`)
    .join('&');
  window.location.href = `${auth_url}?${paramStr}`;
}
export default function* profileSaga() {
  yield takeLatest(authActions.login, startAuthentication);
}
