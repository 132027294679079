import React from 'react';
import YouTube, { Options } from 'react-youtube';
import { IRedditLinkType } from 'services/reddit/types';
import { DEFAULT_META_DATA } from 'types/types';

import Controls from './controls';
import './player.scss';

interface PlayerProps {
  autoplay: 0 | 1;
  activePost: IRedditLinkType;
  onNext: () => void;
  onPrevious: () => void;
  onRandom: () => void;
}

const defaultOptions: Options = {
  height: '136',
  width: '224'
};

const getYTId = (activePost: IRedditLinkType) =>
  activePost &&
  activePost.url &&
  activePost.url.match(
    /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  )![2];

const Player: React.FC<PlayerProps> = ({
  autoplay,
  activePost,
  onNext,
  onPrevious,
  onRandom
}) => {
  const id = getYTId(activePost);
  const { _metadata = DEFAULT_META_DATA } = activePost;
  const opts: Options = {
    ...defaultOptions,
    playerVars: {
      autoplay,
      playsinline: 1
    }
  };

  return (
    <section className="player">
      <div className="content">
        <YouTube className="video" onEnd={onNext} videoId={id} opts={opts} />
        <Controls onRandom={onRandom} onNext={onNext} onPrevious={onPrevious} />
        {activePost.title && (
          <div className="metadata">
            <h3>{_metadata!.artist || 'No Data'}</h3>
            <h4>{_metadata.track}</h4>
            <h4>
              {_metadata.genres} / {_metadata.year}
            </h4>
          </div>
        )}
      </div>
    </section>
  );
};

export default Player;
