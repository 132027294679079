import { createSlice } from 'redux-starter-kit';
import { ISettingsState } from './types';

const initialState: ISettingsState = {
  nsfw: false,
  loading: false,
  error: ''
};

const settings = createSlice({
  slice: 'settings',
  initialState,
  reducers: {
    toggle_nsfw: (state): ISettingsState => {
      state.nsfw = !state.nsfw;
      return state;
    }
  }
});

export const getNSFWFlag = (state: ISettingsState): boolean => state.nsfw;

export default settings;
