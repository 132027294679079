import { IApplicationState } from '../reducer/types';
import {
  getIsLoading,
  getAllPosts,
  getActivePostId,
  getPostById,
  getLastLoadedPostId,
  getAllGenres,
  getActivePostFilter
} from '../reducer';
import { connect } from 'react-redux';

import { postActions, authActions } from 'reducer';
import { IAppDispatchers, IAppStateProps } from './types';
import App from './app';

const mapStateToProps = (state: IApplicationState): IAppStateProps => ({
  loading: getIsLoading(state),
  posts: getAllPosts(state),
  allGenres: getAllGenres(state),
  activePost: getPostById(state, getActivePostId(state)),
  lastPostId: getLastLoadedPostId(state),
  activeFilter: getActivePostFilter(state)
});

const mapDispatchToProps: IAppDispatchers = {
  login: authActions.login,
  loadPosts: postActions.load,
  loadMore: postActions.loadAfter,
  setActivePostId: postActions.setActivePostId,
  next: postActions.next,
  previous: postActions.previous,
  random: postActions.random,
  clear: postActions.clear,
  filter: postActions.filter,
  search: postActions.search
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
