import React from 'react';
import { Icon, Tag } from 'antd';
import classNames from 'classnames';
import { IRedditLinkType } from 'services/reddit/types';
import { DEFAULT_META_DATA } from 'types/types';

import './post.scss';

export enum Mode {
  List,
  Gallery
}

interface PostProps {
  mode: Mode;
  post: IRedditLinkType;
  activeId?: string;
  onClick: (id: string) => void;
}

const Post: React.FC<PostProps> = ({ mode, post, onClick, activeId }) => {
  const active = post.name === activeId;
  const gallery = mode === Mode.Gallery;
  const {
    _metadata = DEFAULT_META_DATA,
    _metadata: { genres = [] } = {}
  } = post;

  return (
    <div
      className={classNames('post', {
        gallery,
        active
      })}
      onClick={() => onClick(post.name)}
    >
      <img
        src={post.thumbnail}
        alt={`thumbnail for ${_metadata.artist}'s song: ${_metadata.track}`}
      />
      <div className="track-info">
        <p className="artist">{_metadata.artist}</p>
        <p className="track">{_metadata.track}</p>
        <span className="genres">
          <Icon type="tag" />
          <Tag>{genres[0]}</Tag>
        </span>
      </div>
    </div>
  );
};

export default Post;
