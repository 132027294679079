export interface SongMetaData {
  artist: string;
  track: string;
  genres: Array<string>;
  year?: number;
  error?: boolean;
}

export const DEFAULT_META_DATA: SongMetaData = {
  artist: '',
  track: '',
  genres: [''],
  year: 0
};
